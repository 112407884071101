.fs-12 {
  font-size: 12px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-35 {
  font-size: 35px !important;
}

.font-normal {
  font-family: "Arbeit", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
}
