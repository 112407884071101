html {
  /* --ion-background-color: #CECECE; */
  /* --ion-backdrop-color: #FFF; */
  /* --ion-toolbar-background: #d33939; */
  /* --ion-toolbar-color: #FFF; */
  /* --ion-item-background: #FFF; */
}

@include media-breakpoint-up(md) {
  ion-menu, .ion-menu {
    min-width: 150px;
    width: 180px;
  }
}
