.split-pane-md.split-pane-visible>.split-pane-side {
  max-width: 20%;
}

ion-avatar img {
  width: 100% !important;
  height: 100% !important;
  max-width: 48px !important;
  max-height: 48px;
}

.scroll-content {
  overflow: scroll;
}

@media print {

  @page {
    margin: 1.7cm 1.3cm;
  }

  // ion-content {
  //   max-width: none;
  // }

  // ion-header {
  //   display: none !important;
  // }

  ion-menu {
    display: none !important;
  }

  .ion-page {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    position: relative;
    // flex-direction: column;
    // justify-content: space-between;
    contain: none;
    overflow: visible;
    // z-index: 0;
  }

  #root,
  body,
  .scroll-content {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin: 0 !important;

    div {
      page-break-inside: avoid;
    }
  }
}